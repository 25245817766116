import { Component, OnInit, Input } from '@angular/core';

import { Toasts } from 'src/app/shared/models/toasts';
import { CustomHttpClient } from '../../services/http-client.service';
import { ToastsService } from '../../services/toast-service';
@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
  // providers:[ToastsComponent]
})
export class ToastsComponent implements OnInit {
  @Input() id: string;
  toasts: Toasts[] = [];
  errorMessage: string = "";
  typeMsg: any = '';
  guid: any = '';

  constructor(private toastsService: ToastsService, private httpClient: CustomHttpClient) {
    this.guid = this.httpClient.getHttpOptions().headers.get('TransactionReference');
   }

  ngOnInit() {
      this.typeMsg = this.toastsService.snackbarMsg;
      if(this.typeMsg.type == 'error') {
        this.errorMessage = this.typeMsg.msg+'<br>'+'error-id:'+' '+this.guid;
      }
  }
  closeToast() {
    this.typeMsg = '';
    this.toastsService.closeToast();
  }

}
