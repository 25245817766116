import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intRateDisp'
})
export class IntRateDispPipe implements PipeTransform {

  transform(value: any, interestType: any): any {
    let intRate;
    if (interestType == 'REF_FIN7') {
      intRate = 7;
    }
    if (interestType == 'REF_FIN8') {
      intRate = 8;
    }
    
    return intRate;
  }

}
