import { Injectable } from "@angular/core";
import { AuthGroup } from "./authorization.types";
import { Observable, BehaviorSubject, Subject } from "rxjs";
//import { HttpClient } from "selenium-webdriver/http";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appConfig } from "../config.service";
import { User } from "./user"
import { AppInsightService } from "../app-insight/appInsights-service";
import { transactionRef } from "../../Enums/customEnums";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  public API_URL: string;
  private currentUserSubject: BehaviorSubject<User>;
  public permissions: Array<string>; // Store the actions for which this user has permission  
  public currentUser: Observable<User>;
  public userObj: any;
  public userLoggedSubject = new Subject<any>();
  httpOptions = {
    headers: new HttpHeaders({
      'TransactionReference': transactionRef.guid
    })
  };

  constructor(private http: HttpClient, public urlConfigs: appConfig,
    private appInsightService:AppInsightService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.API_URL = this.urlConfigs.baseAppUrl + "InkassoSession/";
   // this.userObj = this.userLoginDetails();
  }//private authorizationDataService: AuthorizationDataService
  getUserDetails()
  {
    this.getuserLoggedSubject().subscribe(data=>{
      // this.permissions=data;
      var arr=[];
       data.allowedComponentsList.map((item:any)=>{
         arr.push(item.appCompKey);
       })
       this.permissions=arr;
       console.log( this.permissions)
      })
  }
  hasPermission(authGroup: AuthGroup,permission:any) {
    console.log("authGroup");
    this.permissions=permission;
    console.log( this.permissions);
    if (this.permissions && this.permissions.find(permission => {
      console.log(permission);
      console.log(authGroup);
      console.log(permission === authGroup);
      return permission === authGroup;
    })) {
      return true;
    }
    //  sessions/404
    return false;
  }

  private handleError(error: Response | any) {
    console.error(error);
    return Observable.throw(error);
  }

  setuserLoggedSubject(userData) {
    this.userLoggedSubject.next(userData)
  }
  getuserLoggedSubject() {
    return this.userLoggedSubject.asObservable();
  }

  // Get User Logged In details
  userLoginDetails():Observable<any> {
    this.appInsightService.logMessageTrace('Login Initiated');
    return this.http.get(this.API_URL + 'GetInkassoComponentList', this.httpOptions).map(response => {
      this.appInsightService.logMessageEvent('Get User details after login');
      return response;
    });
   
  }

 
}
