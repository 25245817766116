import {
	Component,
	OnInit,
	Inject,
	ElementRef,
	ViewChild
} from '@angular/core';



import {
	HttpRequest,
	HttpClient,
	HttpEventType,
	HttpErrorResponse
} from '@angular/common/http';


import {
	ToastsService
} from 'src/app/shared/services/toast-service';
import {
	appConfig
} from 'src/app/shared/services/config.service';
import {
	Uploader
} from '../../../models/uploader';
import {
	UploadQueue
} from '../../../models/uploadqueque';
import {
	fileSizeEnum
} from 'src/app/shared/Enums/customEnums';
import {
	MsgUploadService
} from './msg-upload.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomHttpClient } from 'src/app/shared/services/http-client.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppInsightService } from 'src/app/shared/services/app-insight/appInsights-service';
@Component({
	selector: 'msg-file-upload',
	templateUrl: './msg-file-upload.component.html',
	styleUrls: ['./msg-file-upload.component.scss']
})
export class MsgFileUploadComponent implements OnInit {
	uploadBtn: boolean = false;
	uploadQuelength: number = 0;
	uploadDataVal: any = {
		"caseLookupArray": {
			"caseLookup": [{
				"CaseIdentification": 1,
				"CaseIdentificationValue": ""
			}]
		},
		"documentLookupModel": {
			"DocumentType": "",
			"ExternalLink": null,
			"FileDataBase64": "",
			"Important": false,
			"LoggerAt": '',
			"Note": "",
            "OriginalFileName": "",
            "file":""
		}
	}
	accepts: any = ['pdf','PDF', 'jpeg', 'JPEG', 'png', 'PNG','MSG','msg' , 'jpg' , 'JPG'];
	public progressText: string = this.translate.instant("uploadInProgress");
	// this.translate.instant("overviewTabMessagesLabel");
	public message: string;
	public uploader: Uploader = new Uploader();
	apiUrl: any = '';
	fileNameQueue=[];
	fileNameQueueLength:number=0;
  mode: string = 'query'
  @ViewChild('myInput') myInputVariable: ElementRef;
	public progress: any = {
		loaded: 0,
		total: 0
  };
  uploadFileSize = 0;
	constructor(private http: HttpClient,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public urlConfigs: appConfig,
		public dialogRef: MatDialogRef < MsgFileUploadComponent > ,
		private toastservice: ToastsService,
		private msgUploadService: MsgUploadService,
		private _http: CustomHttpClient,
		private translate: TranslateService,
		private appInsight: AppInsightService
	) {
		this.message = '';
		this.apiUrl = this.urlConfigs.baseAppUrl + "InkassoSession/UploadFileToACase";
	}

  //drop the file
	onFilesChange(fileList: Array < File > ) {
		for (let file of fileList) {
			let fileSize = Math.ceil(file.size / 1024 / 1024);
			if (fileSize <= 5) {

				let itemIndex = this.uploader.queue.findIndex(x => x.file.name === file.name);
				if (itemIndex === -1) {
					this.uploader.queue.push(new UploadQueue(file));
					if (this.uploader.queue.length) {
						this.uploadQuelength = this.uploader.queue.length;
					}
				}

			} else {
				this.errorSuccess({
					msg: this.translate.instant("fileSizeLessThan5mb"),
					type: 'error'
				});
			}
		};



	}
//file invalid check
	onFileInvalids(fileList: Array < File > ) {
		for (let file of fileList) {
			// fileSize = fileSize + value.file.size / 1024 / 1024;
			let type = file.name.split('.')[1].toLowerCase();
			if (type === "pdf" || type == "jpeg" || type == "png"|| type == "msg" || type =="jpg") {

			} else {
				this.errorSuccess({
					msg: this.translate.instant("fileTypePdfOrJpg"),
					type: 'error'
				});
			}

		};

		//TODO handle invalid files here
	}
//select the multiple file method
	onSelectChange(event: EventTarget) {
		const fileUpload = document.getElementById('file') as HTMLInputElement;
		fileUpload.onchange = (e) => {
			//let fileSize = Math.ceil(fileUpload.files[0].size / 1024 / 1024);
			for (let index = 0; index < fileUpload.files.length; index++) {
				const file = fileUpload.files[index];
				let fileSize = Math.ceil(fileUpload.files[index].size / 1024 / 1024); // This calculates in MB
			

				if (fileSize <= fileSizeEnum.CaseUploadFileSizeInMB) {
					let type = fileUpload.files[index].name.split('.')[1].toLowerCase();
          if (type === "pdf" || type == "jpeg" || type == "png" || type == "msg" || type == "jpg") {
						let itemIndex = this.uploader.queue.findIndex(x => x.file.name === file.name);
						if (itemIndex === -1) {
							this.uploader.queue.push(new UploadQueue(file));
							if (this.uploader.queue.length) {
								this.uploadQuelength = this.uploader.queue.length;
							}
						}
					} else {
						this.errorSuccess({
							msg: this.translate.instant("fileTypePdfOrJpg"),
							type: 'error'
						});
					}
				} else {
					// make a tost message
					this.errorSuccess({
						msg: this.translate.instant("fileSizeLessThan5mb"),
						type: 'error'
					});
				}

			}
		};
		fileUpload.click();
	}

	
	// upload fill method using api
	upload(id, uploadDataVal) {

		if (id == null)
			return;

		let selectedFile = this.uploader.queue.find(s => s.id == id);
		if (selectedFile) {
			const formData = new FormData();
            formData.append('file', selectedFile.file);
			const uploadReq = new HttpRequest('POST', this.apiUrl, uploadDataVal, {
				reportProgress: true,
			});
			this.mode='query';
			this.appInsight.logMessageTrace('File Uploading Initiated');
			this._http
                .progressHttp(this.apiUrl,uploadDataVal)
				.subscribe((response:any) => {
					let res=response;
					if(res.result.resultCode==0){
						try {
							this.mode='determinate';
							this.fileNameQueue.push({type:'suceess',fileName:selectedFile.file.name});
							this.loadFile();
							this.appInsight.logMessageEvent('FileUploaded Successfully')
						  } catch (err) {
							this.appInsight.logMessageException('Errors in FileUploading: '+err);
							this.toastservice.errorSnackBar({
							  msg: [this.translate.instant("genericErrorMessage") + " (UI-0207001) - " + err],
							  type: 'error'
							});
						  }
					}
					else{
						this.fileNameQueue.push({type:'error',fileName:selectedFile.file.name})
					}
					if(this.uploader.queue.length==1)
					{
						this.progressText = this.translate.instant("fileUploadSuccess");
					}
					else{
						this.progressText = this.translate.instant("fileUploadSuccess");
					}
					this.fileNameQueueLength=this.fileNameQueue.length;
					
					this.uploadQuelength = 0;
				},(error)=>{
					this.appInsight.logMessageException('Errors in FileUpload: '+error);
					this.toastservice.errorSnackBar({
				          msg: ['Status : '+error.status + '  Response :' + error.statusText],
				          type: 'error'
				      });
				})

		}
  }
  //update file table
	loadFile() {
		this.msgUploadService.getFileDetails(this.data.caseGuid).subscribe((fileData: any) => {
			console.log(fileData.data.fileList);
			this.msgUploadService.setFile(fileData.data.fileList);

		})
	}
	//upload all selected files to server
	uploadData() {
		//find the remaning files to upload
		this.uploadBtn = true;
		let remainingFiles = this.uploader.queue.filter(s => !s.isSuccess);
		let failedFileUpload: string = "";
		this.fileNameQueue=[];
		for (let item of remainingFiles) {

			this.getBase64(item.file).then(
				(data: string) => {
					this.uploadDataVal.documentLookupModel.FileDataBase64 = data; //btoa(data);
                    this.uploadDataVal.documentLookupModel.OriginalFileName = item.file.name;
                    this.uploadDataVal.documentLookupModel.file = item.file;
					this.uploadDataVal.documentLookupModel.DocumentType = item.file.name.split('.').pop();
					this.upload(item.id, this.uploadDataVal);
					if (item.file.name === (this.uploader.queue[this.uploader.queue.length - 1]).file.name) {
						//this.errorSuccess({ msg: 'File upload successfuly', type: 'success' });
					} else {
						failedFileUpload = failedFileUpload + "\n" + item.file.name;
					}
				})

			if (failedFileUpload != "") {
				this.errorSuccess({
					msg: 'File not upload for' + failedFileUpload,
					type: 'error'
				});
			}

		}
  }
  //cencel file method
	cancelFile(file: UploadQueue) {

		if (file) {
			if (file.sub) {
				file.sub.unsubscribe();
			}
			this.myInputVariable.nativeElement.value = "";
			this.removeFileFromArray(file);
		}
	}
	private removeFileFromArray(file: UploadQueue) {
		const index = this.uploader.queue.indexOf(file);
		if (index > -1) {
			this.uploader.queue.splice(index, 1);
			if (this.uploader.queue.length == 0) {
				this.uploadQuelength = 0;
			}
		}
	}
  //convert base 64 using getBase method
	getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}
	ngOnInit() {
		if (this.data.hasOwnProperty('dataKey')) {
			// this.uploadBtn=false;
			this.uploadDataVal.caseLookupArray.caseLookup[0].CaseIdentificationValue = this.data.dataKey
		}
	}
	//error message service call
	errorSuccess(message: any) {
		this.toastservice.errorSnackBar(message);
	}

}
