import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { RoutePartsService } from "./shared/services/route-parts.service";

import { filter } from 'rxjs/operators';
import { ValidationService } from './shared/services/validation-service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

import { AuthorizationService } from './shared/services/auth/authorization.service';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { ToastsService } from './shared/services/toast-service';
import { AppInsightService } from './shared/services/app-insight/appInsights-service';
import { CountryCodeService } from './views/service/country-code.service';
import { LanguageService } from './views/service/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  appTitle = 'Sergel';
  pageTitle = '';
  userDetails:any;

  availableLangs = [{
    name: 'English',
    code: 'en',
    lngCode: 'en-US',
    key: 'c=en-US|uic=en-US'
}, {
    name: 'Swedish',
    code: 'sv',
    lngCode: 'sv-SE',
    key: 'c=sv-SE|uic=sv-SE'
}
    , {
    name: 'Finnish',
    code: 'fi',
    lngCode: 'fi-FI',
    key: 'c=fi-FI|uic=fi-FI'
}];

  constructor(
    public title: Title, 
    private router: Router, 
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private validationService:ValidationService,
    private translate: TranslateService, private cookieService: CookieService,
    private authService: AuthorizationService,
    private authGuard:AuthGuard,
    private toastservice: ToastsService,
    private appInsightService:AppInsightService,
    private countrycodeService: CountryCodeService,
    private languageService:LanguageService
  ) {
    
   }

  ngOnInit() {
    // if(!sessionStorage.getItem('userDetails')){  
      this.authService.userLoginDetails().subscribe((data)=>{
        sessionStorage.removeItem("userDetails");
        if(data.result.resultCode == 0){
          if(data){
            let userDetails = data;
            sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
            this.authService.setuserLoggedSubject(data);
            this.appInsightService.setAuthenticatedUserId(data['userID']);
            if(data.allowedComponentsList){
              data.allowedComponentsList.map((item:any)=>{
                this.authGuard.arr.push(item.appCompKey);
              });
            }else{
              if(!data.emailID){
                setTimeout(() => { this.toastservice.errorSnackBar({ msg: [this.translate.instant("userNullError")], type: 'error' }) }, 3000);
                sessionStorage.removeItem("userDetails");
                // sessionStorage.clear();
              }
            }
          }else{
            setTimeout(() => { this.toastservice.errorSnackBar({ msg: [this.translate.instant("userNullError")], type: 'error' }) }, 3000);
          } 
        }
      },error=>{
        setTimeout(() => { this.toastservice.errorSnackBar({ msg: [this.translate.instant("userNullError")], type: 'error' }) }, 3000);
      });
    

    this.changePageTitle();
    this.validationService.init();
    let countryCode: any = this.countrycodeService.getCountryCode()=='se' ? 'sv' : 'fi';
      this.translate.addLangs(['sv', 'en', 'fi']);
      // this.translate.setDefaultLang(countryCode);
    if (this.translate.getBrowserLang() !== undefined) {
      let lang = this.cookieService.get('.AspNetCore.Culture');
      if (lang) {
        var langCode = lang.split("|")[0].split("=")[1].split("-")[0];
        this.translate.use(langCode);
      }else {
        this.translate.use(countryCode);
      }
    }
    else {
      // this.translate.use('sv');
      this.translate.use(countryCode);
    }
    this.appInsightService.logEvent('Message');
    
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length)
        return this.title.setTitle(this.appTitle);
      // Extract title from parts;
      this.pageTitle = routeParts
                      .reverse()
                      .map((part) => part.title )
                      .reduce((partA, partI) => {return `${partA} > ${partI}`});
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
  
}
declare global {
  interface StringConstructor {
    format(str: string, ...args: string[]): string;
  }
};

String.format = function (str: string, ...args: string[]) {
  return str.replace(/{(\d+)}/g, (match, index) => args[index] || '');
}; 
