import {Injectable} from '@angular/core';
import { HttpClient, HttpRequest,HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoaderService } from '../components/loader/service/loader.service';
import { AppInsightService } from './app-insight/appInsights-service';
import { transactionRef } from '../Enums/customEnums';

@Injectable({
    providedIn:'root'
})
export class CustomHttpClient {
  httpClient: HttpClient;
  //http: Http;
  urlPrefix: string;
  public updateTableData = new Subject<any>();
  httpOptions = {
    headers: new HttpHeaders({
      //'Content-Type': 'application/json',
      //'Authorization': `Basic ` + btoa('user:password'),
      'TransactionReference': transactionRef.guid
    })
  };
   
  constructor(httpClient: HttpClient,private loaderSerivce:LoaderService,
    private appInsightService: AppInsightService) {
    this.httpClient = httpClient;
    this.urlPrefix = '';
    this.appInsightService.setCustomProperty(transactionRef.guid);
  }

  getHttpOptions() {
    return this.httpOptions;
  }

  get(url) {
    return this.httpClient.get(this.urlPrefix + url, this.httpOptions).timeout(180000).pipe(catchError((err)=>{return Observable.throw(err)}));
  }
  // getById(url,id){
  //     return this.http.get(this.urlPrefix+url,id);
  // }
  post(url, data) {
    return this.httpClient.post(this.urlPrefix + url, data, this.httpOptions).timeout(180000).pipe(catchError((err)=>{return Observable.throw(err)}));
  }
  request(url,formData)
  {
    const uploadReq = new HttpRequest('POST',url , formData, {
      reportProgress: true,
      headers: this.httpOptions.headers
    });
    return this.httpClient.request(uploadReq).pipe(catchError((err)=>{return Observable.throw(err)}));
  }
  noLoaderHttp(url) {
    return this.httpClient.get(this.urlPrefix + url, this.httpOptions).pipe(
      catchError((err)=>{
        return Observable.throw(err)
      })
    );
  }
  
  progressHttp(url,formData) {
    this.loaderSerivce.stopLoading();
    return this.httpClient.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events',
      headers: this.httpOptions.headers
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      catchError((err)=>{
        return Observable.throw(err)
      })
    );
  }

  private getEventMessage(event: HttpEvent<any>, formData) {
    this.loaderSerivce.stopLoading();
    switch (event.type) {
      case HttpEventType.UploadProgress:
        this.loaderSerivce.stopLoading();
        return this.fileUploadProgress(event);
		break;
      case HttpEventType.Response:
        this.loaderSerivce.stopLoading();
        return this.apiResponse(event);
		break;
      default:''
      
    }
  }

  private fileUploadProgress(event) {
    this.loaderSerivce.stopLoading();
    const percentDone = Math.round(100 * event.loaded / event.total);
    return this.setProgress({progress: percentDone,"progressText":"fileUploadModalUploadInProgress" });
  }

  private apiResponse(event) {
    return event.body;
  }


  setProgress(data:any) {
      this.updateTableData.next(data);
  }
  getProgress() {
      return this.updateTableData.asObservable();
  }
  
}
