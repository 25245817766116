import { Component, Directive, Input, OnInit, ElementRef, ViewChild, AfterViewInit, AfterViewChecked, HostListener } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from '../../services/auth/authorization.service';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../../views/service/language.service';
import { CountryCodeService } from 'src/app/views/service/country-code.service';
import { ToastsService } from '../../services/toast-service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;

  public isActive: string = '';
  public selectedItem: string;
  public loadingMap: boolean = false;
  public sideNavHeight: number = 0;
  public viewMenuHeight: number = 0;
  public viewMainHeight: number = 0;
  public footerHeight: number = 0;
  @ViewChild('mainScreen') public elementMainView: ElementRef;
  @ViewChild('menuScreen') public elementView: ElementRef;
  @ViewChild('ft') public fa1: ElementRef;
  @ViewChild('ftContainr') public ftcontainer: ElementRef;


  public id: number;
  public mode: string;

  private activatedRoute: ActivatedRoute;
  private paramMapSubscription: Subscription;
  userDetails: any;
  userData: Subscription;

  constructor(
    private elementRef: ElementRef,
    activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private authorizationService: AuthorizationService,
    private languageService: LanguageService,
    private cookieService: CookieService,
    private toastservice: ToastsService,
    private countrycodeService: CountryCodeService
  ) {

    try {

      this.activatedRoute = activatedRoute;
      this.checkCookieCulture();
      this.languageService.getLanguage().subscribe(data => {
        this.translate.use(data.language);

      })

      this.userData = this.authorizationService.getuserLoggedSubject().subscribe((userDetails) => {
        this.userDetails = userDetails;
      });

    }
    catch (err) {

        this.toastservice.errorSnackBar( {
          msg: [this.translate.instant("genericErrorMessage") + " (UI-0702001) - " + err],
          type: 'error'
      });

    }

  }

  ngOnInit() {

    try {

      this.userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
      if (this.activatedRoute.firstChild) {
        this.isActive = this.activatedRoute.firstChild.snapshot.data['auth'];
      }

    }
    catch (err) {

      this.toastservice.errorSnackBar( {
          msg: [this.translate.instant("genericErrorMessage") + " (UI-0702002) - " + err],
          type: 'error'
      });

    }


  }

  checkCookieCulture() {

    try {

      let countryCode: any = this.countrycodeService.getCountryCode() == 'se' ? 'sv' : 'fi';
      this.translate.addLangs(['sv', 'en', 'fi']);
      // this.translate.setDefaultLang(countryCode);
      if (this.translate.getBrowserLang() !== undefined) {
        let lang = this.cookieService.get('.AspNetCore.Culture');
        if (lang) {
          var langCode = lang.split("|")[0].split("=")[1].split("-")[0];
          this.translate.use(langCode);
        }else {
          this.translate.use(countryCode);
        }
      }
      else {
        // this.translate.use('sv');
        this.translate.use(countryCode);
      }
    }
    catch (err) {

      this.toastservice.errorSnackBar( {
          msg: [this.translate.instant("genericErrorMessage") + " (UI-0702003) - " + err],
          type: 'error'
      });

    }


  }

  // I get called once when the component is being unmounted.
  public ngOnDestroy(): void {

  }
  ngAfterViewInit() {


    this.setMenuHieght();
  }
  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.setMenuHieght();
  }

  isAllowed(componentName) {
    try {

      let allowed = false;
      if (this.userDetails.allowedComponentsList) {

        this.userDetails.allowedComponentsList.forEach(element => {
          if (element.appCompKey.toLowerCase() == componentName.toLowerCase()) {
            allowed = true;
            if (element.appCompKey == 'register-case' && element.read) {
              allowed = false;
            }
          }
        });
      }
      if (allowed) {
        return true;
      }
      else {
        false;
      }
    }
    catch (err) {

      this.toastservice.errorSnackBar( {
        msg: [this.translate.instant("genericErrorMessage") + " (UI-0702004) - " + err],
        type: 'error'
      });
    }

  }

  setMenuHieght() {

  }
  onResize(event) {

    this.setMenuHieght();
  }

}
