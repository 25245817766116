import { Guid } from "guid-typescript";

export class customEnums {

}

export enum Currency {
  Sweden = "SEK"
}

export enum fileSizeEnum {
  CaseUploadFileSizeInMB = 5
}

export const  transactionRef = {
  guid: Guid.raw()
}